/* eslint-disable import/prefer-default-export */
import RANTANGAN_22_23_1 from './22_23/1.svg';
import RANTANGAN_22_23_2 from './22_23/2.svg';

import RANTANGAN_23_24_1 from './23_24/1.svg';

import RANTANGAN_24_25_1 from './24_25/1.svg';

const RANTANGAN_24_25 = [RANTANGAN_24_25_1];

const RANTANGAN_23_24 = [RANTANGAN_23_24_1];

const RANTANGAN_22_23 = [RANTANGAN_22_23_1, RANTANGAN_22_23_2];

export { RANTANGAN_24_25, RANTANGAN_23_24, RANTANGAN_22_23 };
